import { useState, useEffect } from 'react'


const useMediaQuery = (queryString) => {
  const mqmInitialValue = typeof window !== 'undefined' ? window.matchMedia(queryString).matches : null
  const [mediaQueryMatches, setMediaQueryMatches] = useState(mqmInitialValue)
  
  useEffect(() => {

    const handleWindowResize = (event) => {
      setMediaQueryMatches(event.matches)
    }

    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia(queryString)
      mediaQuery.addEventListener('change', handleWindowResize)

      return () => mediaQuery.removeEventListener('change', handleWindowResize)
    }
  }, [queryString])

  return mediaQueryMatches
}

export default useMediaQuery